.wrapper{
    background-color: var(--artical-background-page);
}


  
@media (min-width: 992px){
    .content {
        padding: 30px 0;
    }
}
