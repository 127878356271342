.offcanvas {
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    left: -395px;
    width: 375px;

    background: var(--artical-background-panel);
    -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);

    &.offcanvas-right {
        right: -395px;
        left: auto;
    }


    &.offcanvas-on{
        -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
        transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
        right: 0;
        left: auto;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
          }
    }

    .offcanvas-header{
        h4{
            color: var(--artical-color-dark);
            font-size: $font-size-base;
        }
        
    }
    .offcanvas-content{
        h5{
            color: var(--artical-color-dark);
        }
    }
}


@media (max-width: 991.98px){
    .offcanvas {
        width: 300px;
    }
}