.rtl{
    direction: rtl;
    text-align: right;

    ul, ol{
        padding-right: 0;
    }

    .mr-2, .mx-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    .mr-3, .mx-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .ml-2, .mx-2 {
        margin-right: 0.5rem !important;
        margin-left: 0 !important;
    }
    .ml-3, .mx-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    .ml-5, .mx-5 {
        margin-left: 0 !important;
        margin-right: 3rem !important;
    }
    .pl-1, .px-1 {
        padding-left: 0 !important;
        padding-right: 0.3rem !important;
    }
    .pr-1, .px-1 {
        
        padding-left: 0.25rem !important;
    }
    .pl-2, .px-2 {
        padding-right: 0.5rem !important;
        padding-left: 0 !important;
    }
    .pl-3, .px-3 {
        
        padding-right: 1rem !important;
    }
    .pr-3, .px-3 {
        padding-left: 1rem !important;
        padding-right: 0 !important;
    }
    .pr-5, .px-5 {
        padding-left: 3rem !important;
        padding-right: 0 !important;
    }
   .pl-4{
         padding-left: 0 !important;
         padding-right: 1.5rem !important;
   }
   .ml--4{
    margin-right: -1.25rem !important;
    margin-left: 0 !important;
    }
    .text-right {
        text-align: left !important;
    }
    .dropdown-menu-right {
        right: auto;
        left: 0;
    }

    .btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
    .btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }

    .sticky-toolbar {
        left: 0;
        right: auto;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.42rem;
        border-bottom-right-radius: 0.42rem;
    }

    .aside {
        right: 0;
        left: auto;

        .aside-menu{
            .nav-item{
                .collapse{
                    .svg-icon{
                        svg{
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
    &.aside-enabled .header.header-fixed {
        left: 0;
        right: 265px;
    }
    &.aside-minimize .header.header-fixed {
        left: 0;
        right: 70px;
    }

    &.aside-fixed.subheader-enabled .subheader {
        right: 265px;
        left: 0;
    }
    &.aside-minimize.subheader-enabled .subheader {
        right: 70px;
        left: 0;
    }


    &.aside-fixed .wrapper {
        padding-left: 0;
        padding-right: 265px;
    }
    &.aside-minimize .wrapper {
        padding-left: 0;
        padding-right: 70px;
    }

    .offcanvas.offcanvas-right {
        left: -395px;
        right: auto;
    }

    .offcanvas.offcanvas-on {
        
        left: 0;
        right: auto;
    }


    .topbar{
        .dropdown {
            .dropdown-menu {
                .nav .nav-item .nav-link .nav-text {
                    text-align: right;
                }
            }
        }
    }


    .theme-circle{
       
        &:before{
            left: -30px;
            right: auto;
            
        }
        &:after{
            left: -30px;
            right: auto;
        }
    }

    .timeline.timeline-6:before {
        left: auto;  
        right: 54.5px;
    }
    .history-detail .timeline.timeline-6::before {
        left: auto;  
        right: 0;
    }
    .history-detail .timeline .badge-date::before{
        left: auto;
        right: -39px;
    }
    .message-content .text-input::before{
        left: auto;
        right: -7px;
        border-width: 1px 0 0 1px;

    }
    .addclip-input{
        right: auto;
        left: 15px;
    }
    select.form-control:not([multiple=multiple]) {
        background-image: url(../../images/misc/down-arrow.png);
        background-position: 1% 10px, calc(100% - 20px) 13px, 100% 0;
    }
    .slick-slider {
        direction: ltr;
    }
    .ms-drop ul>li label{
        padding-right: 1.25rem;
        padding-left: 0;
    }
    .ms-drop input[type="radio"], .ms-drop input[type="checkbox"]{
        margin-right: -1.25rem;
        margin-left: 0;
    }
    .ms-choice>span{
        left: 20px;
        right: 0;
        padding-right: 8px;
        padding-left: 0;
        text-align: right;
    }
    .ms-choice>div.icon-caret{
        left: 8px;
        right: auto;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow{
        right: auto;
        left: 1px;
    }

}


@media (max-width: 991.98px){
    .rtl{
        .ml-2, .mx-2 {
            margin-left:0 !important;
            margin-right: 0.5rem !important;
        }
        .aside {
            right: -295px;
            left: auto;

            .brand {
                margin-left: 0;
                margin-right: -13px;
            }
            
        }
        .aside.aside-on {
            right: 0;
            left: auto;
        }

        &.aside-fixed .wrapper {
            padding-left: 0;
            padding-right: 0;
        }

        &.aside-fixed.subheader-enabled .subheader {
            right: 0;
        }

    }
}

