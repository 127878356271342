.topbar {
    
    display: flex;
    align-items: stretch;
    padding: 0;
    .dropdown{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;


        .dropdown-menu{
            border: none;

            box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
            background-color: var(--artical-background-panel);
          

            .nav {
                padding: 0;
                margin: 0;
                display: block;
                list-style: none;
                height: 295px;
                overflow-y:scroll;
                
                .nav-item{
                    padding: 0;
                    display: block;
                    list-style: none;

                    

                    &:hover{
                        background-color: var(--artical-background-module);
                        text-decoration: none;
                    }


                    
                    .nav-link{
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        padding: 0.75rem 1.5rem;
                        

                        
                        .nav-icon {
                            line-height: 0;
                            -ms-flex-negative: 0;
                            flex-shrink: 0;
            
                            -webkit-box-flex: 0;
                            -ms-flex: 0 0 3rem;

                            flex: 0 0 3rem;
                            background-color: var(--artical-background-element);
                            height: 3rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 20px;
                        }

                        .nav-text {
                            color: var(--artical-color-dark);
                            font-size: 1rem;
            
                            .text-muted {
                                color: var(--artical-color-dark-secondary) !important;
                            }
                        }
                    }
        

                } 
                
            }


            .dropdown-item{
                display: block;
                width: 100%;
                padding: .8rem 1.6rem;
                clear: both;
                color: var(--artical-color-dark-secondary);
                text-align: inherit;
                background-color: transparent;
                border: 0;

                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;

                .svg-icon{
                    flex: 0 0 25px;
                }

                &:focus, &:hover{
                    color: var(--artical-color-primary);
    
                }
            }
        }
    }
    .topbar-item {
       
        display: flex;

        align-items: center;

        .btn.btn-icon {
            position: relative;
            display: inline-flex;
            
            align-items: center;
            justify-content: center;
            padding: 0;
            height: calc(1.5em + 1.65rem + 2px);
            width: calc(1.5em + 1.65rem + 2px);
            
           

            .symbol{
                display: inline-block;
                -ms-flex-negative: 0;
                flex-shrink: 0;
                position: relative;
                border-radius: $border-radius;

                .symbol-label {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    font-weight: 500;
                    line-height: 0;

                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    border-radius: $border-radius;
                    background-color: var(--artical-background-element);
                    color:  var(--artical-color-dark-secondary);
                }

                &.symbol-35{
                    .symbol-label{
                        width: 35px;
                        height: 35px;
                    }
                }
            }

            .badge{
                position: absolute;
                top: 2px;
                right: 2px;
            }
        }

        .btn.btn-clean {
            color:  var(--artical-color-dark-secondary);
            background-color: transparent;
            border-color: transparent;
        }

        .btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-clean:focus:not(.btn-text), .btn.btn-clean.focus:not(.btn-text) {
            color: var(--artical-color-primary);
            background-color: var(--artical-background-module);
            border-color: transparent;
        }


        
    }

    .quick-search .quick-search-form {
        padding: 0;


        .input-group-prepend {
            background-color: transparent;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-right: -1px;

            .input-group-text {
                background-color: transparent;
                border: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                padding: 0.3rem 1rem;
                margin-bottom: 0;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: var(--artical-color-dark-secondary);
                text-align: center;
                white-space: nowrap;


                .svg-icon.svg-icon-lg svg {
                    height: 1.25rem !important;
                    width: 1.25rem !important;
                }
            }
        }

        .form-control {

            border: 0;
            background-color: transparent;
            color:  var(--artical-color-dark-secondary);
            outline: none !important;
            -webkit-box-shadow: none;
            box-shadow: none;
            padding-left: 0;
            padding-right: 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding: 0;
            border-radius: 0;
        }
    }
}
@media (min-width: 992px){
    
    .header{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 65px;
        position: relative;
        z-index: 2;
        .header-menu-wrapper{
            -webkit-box-align: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
            .header-menu .menu-nav > .menu-item {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                padding: 0px 0.25rem;
                padding: 0px 0.25rem;

                .btn.btn-icon {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    height: calc(1.5em + 1.3rem + 2px);
                    width: calc(1.5em + 1.3rem + 2px);

                    
                }
                .btn.btn-clean {
                    color:  var(--artical-color-dark-secondary);
                    background-color: transparent;
                    border-color: transparent;
                }
                .btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-clean:focus:not(.btn-text), .btn.btn-clean.focus:not(.btn-text) {
                    color: var(--artical-color-primary);
                    background-color: var(--artical-background-module);
                    border-color: transparent;
                }
            }

        }
    }
    .header-menu{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        height: 100%;
        margin: 0;

        .menu-nav {
            
            list-style: none;
            margin: 0;
            padding: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
        }
    }
    .header.header-fixed {
        height: 65px;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 97;
        background-color: var(--artical-background-panel);
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
        transition: all 0.2s ease-out;
    }
    .header-fixed .wrapper {
        padding-top: 64px;
    }
    .header-fixed.subheader-enabled .wrapper {
        padding-top: 50px;
    }
    .aside-enabled .header.header-fixed {
        left: 265px;
    }

    .aside-minimize .header.header-fixed {
        left: 70px;
        
    }
}

@media (max-width: 991.98px){
    .header-mobile-fixed .header-menu-wrapper {
        display: none;
    }

    .topbar {
        position: fixed;
        z-index: 96;
        top: 0;
        left: 0;
        right: 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 15px;
        height: 50px;
        background-color: var(--artical-background-module);
        -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
        border-top: 1px solid transparent;
    }

    .topbar-mobile-on.header-mobile-fixed .topbar {
        z-index: 96;
        margin-top: 55px;
    }

    .header-mobile-fixed .header-mobile {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .quick-search{
        display: none;
    }
}



.lds-ripple {
    
    position: absolute;
    width: 44px;
    height: 44px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  .lds-ripple div {
    position: absolute;
    border: 3px solid #000;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 11px;
      left: 11px;
      width: 22px;
      height: 22px;
      opacity: 0;
    }
    30% {

        opacity: 0.2;
      }
      50% {

        opacity: 0.1;
      }
    80% {

        opacity: 0.05;
      }
    100% {
      top: 0;
      left: 0;
      width: 44px;
      height: 44px;
      opacity: 0;
    }
  }
  