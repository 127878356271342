.login-form-logo{
    margin: 20px;
}

.login{
    text-align: center;
    background-color: white !important;
}
.auth-inner-wrapper {
    border-radius: 5px;
    box-shadow: 0 5px 10px 0 #eff1f5;
    border: solid 1px #edf2f9;
    background-color: #fff;
    padding: 40px 40px;
    max-width: 450px;
    margin: 0 auto;
    width: 100%;
  }
  .auth-logo-image {
    text-align: center;
    padding-bottom: 34px;
  }
  .auth-logo-image img {
    width: 100%;
    max-width: 225px;
  }

.auth-main-wrapper {
    background-color: #f9fafc;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
   
  }
  .site-input {
    margin-bottom: 20px;
    position: relative;
  }

  .site-input .eyes-icon{
    position: absolute;
    top: 44px;
    right: 12px;
    z-index: 11;
    cursor: pointer;
  }
  
  .site-input input {
    width: 100%;
    height: 50px;
    padding: 0;
    background: #fff;
    padding: 0 18px;
  }
  .site-input input.type-password{
    padding-right: 35px;
  }
.login-btn{
  background-color: #ee7900 !important;
  margin-top: 20px;
  width: 100%;
 
  color: white !important;
}
.login-btn a{
    color: white;
}

.auth-inner-wrapper form .login-btn{
  margin-top: 15px;
  height: 50px;
  font-size: 16px;
}
.auth-inner-wrapper form .login-btn:disabled{
  opacity: 1 !important;
  color: rgba(0,0,0,.26) !important;
  background-color: #e0e0e0!important;
  border-color: #e0e0e0!important;
}
.auth-inner-wrapper .site-input input{
  height: 50px;
  background: #fff;
}
.auth-inner-wrapper .site-input input:hover{
  border-color: #ee7900;
  box-shadow: none;
}
.auth-inner-wrapper .site-input input:focus{
  border-color: #ee7900;
  box-shadow: 0px 0px 0px 1px #ee7900;
}
.auth-inner-wrapper .site-input label{
  font-size: 16px;
}

@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
      width: 100%;
      max-width: 450px;
    }
  }