
.dd { position: relative; display: block; list-style: none; }

.dd-list { display: block; position: relative; margin: 0; padding: 0; list-style: none; }
.dd-list .dd-list { 
    padding-left: 30px; 
    position: relative;
    &::before{
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: var(--artical-color-border);
        left: 15px;

    }
    &::after{
        content: "";
        position: absolute;
        bottom: 0;
        width: 98.5%;
        height: 1px;
        background-color: var(--artical-color-border);
        left: 15px;
    }
    .dd-handle{
        box-shadow: none;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            height: 1px;
            width: 13px;
            background-color: var(--artical-color-border);
            left: -14px;
        }
    }
}
.dd-collapsed .dd-list { display: none; }

.dd-item,
.dd-empty,
.dd-placeholder { display: block; position: relative; }

.dd-handle {
    font-size: $font-size-lg;
    position: relative;
    display: block; 
    height: 50px;
    margin: 5px 0;
    padding: 11px 25px; 
    padding-left: 40px;
    color: var(--artical-color-dark); 
    text-decoration: none; 
    font-weight: bold; 
    border: 1px solid var(--artical-color-border);
    background: transparent;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box; -moz-box-sizing: border-box;
}
.inner-content{
    position: absolute;
    top: 0;
    right: 25px;
    display:flex;
    justify-content: space-between;
    padding: 15px;
    height: 50px;
    i{
        font-size:  $font-size-lg;
    }
}
.dd-handle:hover {
     color: var(--artical-background-primary);
    }

.dd-item > button { 
    display: block; 
    position: relative; 
    cursor: pointer; 
    float: right; 
    width: 40px; 
    height: 40px; 
    margin: 5px 0; 
    padding: 0; 
    text-indent: 100%; 
    white-space: nowrap; 
    overflow: hidden; 
    border: 0; 
    background: transparent; 
    font-size: $font-size-base; 
    text-align: center; 
    outline: none;
    color: var(--artical-color-dark); 
}
.dd-item > button:before { content: '\f078';
    font-weight: 900;
    font-family: 'Font Awesome\ 5 Free';
    display: block; position: absolute; width: 100%; text-align: center; text-indent: 0; 
    z-index: 2;
}
.dd-item > button[data-action="collapse"]:before {
    content: '\f077';
    font-weight: 900;
    font-family: 'Font Awesome\ 5 Free';
    z-index: 2;
 }

.dd-placeholder,
.dd-empty { margin: 5px 0; padding: 0; min-height: 30px; background: #f2fbff; border: 1px dashed #b6bcbf; box-sizing: border-box; -moz-box-sizing: border-box; }
.dd-empty { border: 1px dashed #bbb; min-height: 100px; background-color: #e5e5e5;
    background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
                      -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-image:    -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
                         -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-image:         linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
                              linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
}

.dd-dragel { position: absolute; pointer-events: none; z-index: 9999; }
.dd-dragel > .dd-item .dd-handle { margin-top: 0; }
.dd-dragel .dd-handle {
    -webkit-box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
            box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
}

/**
 * Nestable Extras
 */

.nestable-lists { display: block; clear: both; padding: 30px 0; width: 100%; border: 0; border-top: 2px solid #ddd; border-bottom: 2px solid #ddd; }

#nestable-menu { padding: 0; margin: 20px 0; }

#nestable-output,
#nestable2-output { width: 100%; height: 7em; font-size: 0.75em; line-height: 1.333333em; font-family: Consolas, monospace; padding: 5px; box-sizing: border-box; -moz-box-sizing: border-box; }

#nestable2 .dd-handle {
    color: #fff;
    border: 1px solid #999;
    background: #bbb;
    background: -webkit-linear-gradient(top, #bbb 0%, #999 100%);
    background:    -moz-linear-gradient(top, #bbb 0%, #999 100%);
    background:         linear-gradient(top, #bbb 0%, #999 100%);
}
#nestable2 .dd-handle:hover { background: #bbb; }
#nestable2 .dd-item > button:before { color: #fff; }
