$sidebar-bg-color: var(--artical-background-panel) !important !default;
$sidebar-color: #b6bdc4 !default;
// $sidebar-width: 270px !default;
$sidebar-width: 265px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color:  var(--artical-color-primary) !important !default;
$submenu-bg-color: var(--artical-background-panel) !important !default;
$submenu-bg-color-collapsed: var(--artical-background-panel) !important !default;
$icon-bg-color: #ffffff !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;



.mr-top{
    margin-top: 12px;
}
.nav-new{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: unset !important;
    flex-wrap: unset !important;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    overflow-x: auto;
    padding-bottom: 5px;
    
&::-webkit-scrollbar {
    width: 0.5px;
    height: 2px; 
}
  
 &::-webkit-scrollbar-thumb {
    background-color: $highlight-color;
    border-radius: 2px; }
}

.imge-brand{
    height: 100px;
    width: 100px;
}
.drp{
    margin-top: 12px;
}
.scroll-maunal{
    overflow: auto;

    &::-webkit-scrollbar {
        width: 3px !important;
        height: 5px !important;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1; 
    }
    // &::-webkit-scrollbar-thumb:hover {
    //     background:  #fa942a; 
    // }
}


.fontt{
   font-size: 13px;
}

.errorText{
    color: red;
}

.scrolling2{
    overflow: hidden ;

    .scrolling{
        overflow: auto;
        white-space: nowrap;
        flex-wrap: nowrap   !important;
    }
}

.pro-menu-item.active .pro-item-content a {
    color: var(--artical-color-primary) !important;
}


///for other page

.shadow{
   box-shadow:none
      
}


 
.shadow::after{
    display: none !important; 
 }


 .rtl{


    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
        margin-right: 0px;
        margin-left: 10px;
    }
    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
        text-align: right;
    }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper{
        left: 20px;
        right: 0px;
    } 
    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
        padding: 8px 20px 8px 35px;
    } 
 }
 .MuiPaper-root{
     background-color: var(--artical-background-panel) !important;
     color: var(--artical-color-dark) !important;
 }
 .MuiInputBase-root{
    color: var(--artical-color-dark) !important;
 }
 .MuiInput-underline:before,.MuiTableCell-root{

     border-color: var(--artical-color-border) !important;
 }
 .MTableHeader-header-13{
    background-color: var(--artical-background-panel) !important;
    color: var(--artical-color-dark) !important;
 }
 .MuiIconButton-colorInherit{
    color: var(--artical-color-dark) !important;
 }
 .MuiInput-underline:after{
     border-color: var(--artical-color-primary) !important;
 }
 .MTablePaginationInner-root-14,.MuiIconButton-root.Mui-disabled,.MuiIconButton-root{
    color: var(--artical-color-dark) !important;
 }

 
.MuiSwitch-colorSecondary.Mui-checked{
    color: var(--artical-color-primary) !important ;
}

.MuiSwitch-colorSecondary.Mui-checked  + .MuiSwitch-track{
    background-color: var(--artical-color-primary) !important ;
}

.settings-lebel{
    padding-bottom:3px;
     font-weight:bold
}
.shadows{
    box-shadow: none !important 
}

.dropdown-toggle::after{
    display: none  !important;
}

.login-forms{
    li {
       display: block !important;
    }
}

 .custom-loader{
    align-content: center;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--artical-background-panel) !important;
 }
 .login-text-margin{

    color: red;
    
 
 }



 .react-tagsinput-tag {
    background-color: var(--artical-color-primary) !important ;
    border-radius: 2px;
    border: 1px solid  var(--artical-color-primary) !important;
    color:  var(--artical-background-panel)  !important ;
    
 }

 .react-tagsinput--focused {
    border-color: var(--artical-color-primary) !important
}

.react-tagsinput-tag a::before {
    color: var(--artical-background-panel)  !important ;
}
.react-tagsinput {
    background-color: var(--artical-background-panel) !important ;
}


.tagtext{
    text-align:center;
    align-content:center;
     direction:flex;
      align-self:center;
       align-items:center;
        width:100%
}


.delay-text-area{
    height: 125px  !important;
}


.h-387px{
    height: 387px !important;
}


html,
      body,
      body > div:first-child,
      div#__next,
      div#__next > div {
        height: 100%;
      }



      @media (max-width: 768px){
        .pro-sidebar.md { 
            z-index: 9999;
            display: contents;
         }
      }
         


  .css-yk16xz-control{
      width: 363px !important;
  }    
.css-2b097c-container{
    width: 363px !important;

}

  .slc{
      width: 42px !important;
  }


  .imageshow{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .imageIcons{
      height: 80px;
      width: 80px;
      margin-bottom: 10px;
  }


  .l-icon{
      font-size: 40px;
  }
  #modallll{
      position: relative;
      &:hover{
          .hoverdiv{
              display: flex;
          }
      }
  }
  .hoverdiv{
      display: none;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: #b6bdc4;
      opacity: 0.6;
      i{
        font-size: 40px;
      }
  }



#modallll1{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll2{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll3{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll4{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll5{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll6{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll7{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll8{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll9{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll10{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll11{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll12{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll13{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll14{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
#modallll15{
    position: relative;
    &:hover{
        .hoverdiv2{
            display: flex;
        }
    }
}
.ft-size{
    font-size: 1.1rem !important;;
}
.ft-size2{
    font-size: 0.9rem !important;;
}
.hoverdiv2{
    display: none;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #b6bdc4;
    opacity: 0.6;
   
}




.f-l{
    float: right !important
}
.auth-main-wrapper-404 {
    background-color: #f9fafc;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 40px 20px;
}

.not-found-wrapper {
    text-align: center;
    .logo-here {
        img {
            max-width: 225px;
        }
    }
}
.not-found-text {
    padding-top: 50px;
    h1 {
        color: #000;
        margin: 0 0 0;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.3;
    }
    h3 {
        color: #141414;
        font-size: 20px;
        margin: 11px 0 0;
        font-weight: 400;
        line-height: 1.5;
    }
    .site-btn {
        border-radius: 3px;
        background-color: #ee7900;
        display: inline-block;
        text-decoration: none;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        padding: 13px 38px;
        margin-top: 30px;
    }
    h4 {
        margin: 30px 0 0;
        color: #141414;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.55;
        a {
            color: #8367e8;
        }
    }
    p {
        margin: 2px 0 0;
        color: #75839e;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.55;
    }
}

// REWAMP SCSS


.table-search {
	.form-wrapper {
		position: relative;
		width: 100%;
        min-width: 295px;
		input {
			width: 100%;
			height: 40px;
			padding: 0 12px 0 30px;
			background: #fff;
			border: 1px solid #ddd;
			border-radius: 4px;
			font-size: 14px;
            outline: none;
		}
		i {
			position: absolute;
			top: 50%;
			left: 10px;
			z-index: 1;
			transform: translateY(-50%);
			color: #7e7e7e;
		}
	}
}


.custom-table-wrapper {
    .table-data-results {
        padding-bottom: 20px;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        h5 {
            color: #000;
            font-size: 14px;
            font-weight: 600;
            margin: 0;
        }
    }
}
.custom-table-inner {
    border-radius: 5px;
    box-shadow: 0 5px 10px 0 #eff1f5;
    border: 1px solid #edf2f9;
    background-color: #fff;
    position: relative;
    &.active{
        min-height: 520px;
        overflow: auto;
    }
    table {
        width: 100%;
        min-width: 700px;
        thead {
            tr {
                th {
                    color: #000;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.64;
                    border-bottom: 1px solid #e6ebf4;
                    text-align: left;
                    padding: 15px 15px;
                    white-space: nowrap;
                }
            }
        }
        tbody {
            position: relative;
            tr {
                td {
                    padding: 13px 15px;
                    border-bottom: 1px solid #e6ebf4;
                    font-size: 13px;
                    max-width: 450px;
                    .img-prd {
                        width: 69px;
                        height: 69px;
                        border-radius: 5px;
                        object-fit: cover;
                    }
                    p {
                        margin: 0 0 0px;
                        font-size: 13px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    .table-action-btns {
                        display: flex;
                        .button {
                            color: #000;
                            border-radius: 3px;
                            background-color: #e6ebf4;
                            width: 45px;
                            height: 40px;
                            padding: 0;
                            min-width: auto;
                            margin-right: 5px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            &:last-child {
                                margin-right: 0;
                            }
                            img {
                                width: 20px;
                                height: 16px;
                                display: block;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}


ul {
    &.pagination-listing {
        padding: 20px 0 0;
        margin: 0;
        list-style: none;
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-end;
        li {
            margin: 3px;
            a {
                background: #fff;
                color: #222;
                border: 1px solid #e6ebf5;
                padding: 7px 12px;
                border-radius: 3px;
                font-size: 14px;
                cursor: pointer;
                transition: all .2s;
                display: inline-block;
                min-width: 36px;
                text-align: center;
                text-decoration: none;
            }
            &.disabled {
                a {
                    pointer-events: none;
                    background: #e6ebf4;
                    opacity: .7;
                    display: none;
                }
            }
        }
    }
}

ul.pagination-listing li a:hover,
ul.pagination-listing li.active a {
	background: #ee7900;
	color: #fff !important;
}


.custom-loader-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 555;
    display: grid;
    place-items: center;
    min-height: 510px;
}
.custom-loading-icon{
  animation: helloCustomLoader 1.1s linear infinite;
  width: 60px;
  height: 60px;
}

.pointer-none{
    // pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
}

.btn-gray {
    background-color: #ddd !important;
    border-color: #ddd !important;
}


@keyframes helloCustomLoader {
 0%{
    transform: rotate(0deg);
   }
100%{
    transform: rotate(360deg);
   }
}