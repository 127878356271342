.thumbnail .thumbnail-imges:hover> .thumbnail >.detail-link {
    display: flex;
  }
  .thumbnail.active  {
    border: 2px solid !important;
    border-color: var(--artical-color-primary) !important;
}
.thumbnail{
    border: 2px solid !important;
    border-color: transparent !important;
    border-radius: 10px;
    height: 115px;
    position: relative;
    overflow: hidden;
    .detail-link{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;z-index: 1;
        display: flex;
     a{
         display: none !important;
        color: var(--artical-background-panel);
        svg{
            width: 20px;
            height: 20px;
            fill: var(--artical-background-panel);
        }
     }
     &:hover{
       a{
        display: flex !important;
       }
       ~.thumbnail-imges{
           &::before{
               opacity: 0.6;
           }
       }
     }
     
    }
    .thumbnail-imges{
      
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        height: 100%;
        &::before{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: var(--artical-background-primary);
            opacity: 0;
        }
      &:hover{
          &::before{
              opacity: 0.6;
          }
            > .detail-link{
              display: flex;
          }
      }
      .img-select{
          width: 100%;
          height: 100%;
      }
        img{
            height: 100%;          
            width: 100%;
        }
       
    }
  
}

.dark{
    .thumbnail{
        .active  {
            border-color:white !important;
        }
    }
}
.avatar-upload {
    position: relative;
  
    .avatar-edit {
        position: absolute;
        right: 0;
        z-index: 1;
        top: 0;
        width: 100%;
        text-align: center;
        height: 100%;
     
        font-size: 25px;
      color: transparent;
        opacity: 1;
        input {
            display: none;
            + label {
                display: inline-block;
             
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
               
             
            }
        }
        &:hover {
                   background-color: lighten($black, 5%);
                   opacity: 0.5;
                   color: var(--artical-background-element);
        }
    }
    .avatar-preview {
        height: 192px;
        position: relative;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        > div {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
.media-categories{
    padding: 50px 0;
  
      ul{
        overflow: auto;
        white-space: nowrap;
        flex-wrap: nowrap;
  
      }
            /* width */
            ::-webkit-scrollbar {
                width: 3px !important;
                height: 5px !important;
            }
            
            /* Track */
            ::-webkit-scrollbar-track {
                background: #f1f1f1; 
            }
            
            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: #c1c1c1; 
            }
            ::-webkit-scrollbar-thumb:hover {
                background: $primary; 
            }
            // :hover::-webkit-scrollbar {
            //     width: 3px !important;
            //     height: 10px !important;
            // }
}

.loadingmore {
    display: none;
}
.noContent {
    color: var(--artical-color-body) !important;
    background-color: transparent !important;
    pointer-events: none;
    border: none;
    &:focus{
        box-shadow: none;
    }
  }


  @media (min-width:768px){
    .thumbnail{
        height: 200px;
    }
  } 